<template>
  <!-- Container fluid  -->
  <div class="container-fluid">
      <!-- Start Page Content -->
      <div class="row">
          <div class="col-12">
              <div class="card">
                  <div id="report" class="card-body">
                      <div class="container-fluid">
                        <div style="width: 80px;">
                          <img v-bind:src="$store.state.logo" style="max-width:100%; max-height:100%; display:block;"/>
                        </div>
                        <div class="headerreport">
                          <div class="row">
                            {{ companydata.cmp_nmbre }}
                          </div>
                          <div class="row">
                            {{ fecha }}
                          </div>
                        </div>
                      </div>
                      <br>
                      <div class="card-title">
                        <h1 class="titlereport">Reporte de Documentos creados por usuario</h1>
                        <h5 class="subtitle">Usuario: {{ username }}</h5>
                        <h5 class="subtitle">{{ rango }}</h5>
                      </div>
                      <div id="wrapping" class="table-responsive m-t-40 bodyreport">
                          <table id="reporteDocsRadUsuario" class="table table-sm display nowrap table-striped table-bordered" cellspacing="0" width="100%">
                              <thead>
                                  <tr>
                                      <th>#</th>
                                      <th>Clase documento</th>
                                      <th>Año</th>
                                      <th>Consecutivo</th>
                                      <th>Fecha Radicación</th>
                                      <th class="entidad">Entidad</th>
                                      <th class="asunto">Asunto</th>
                                      <th class="dependencia">Dependencia</th>
                                      <th class="serie">Serie</th>
                                  </tr>
                              </thead>
                              <tbody>
                                  <tr v-for="(item, index) in items" class="rowstyle">
                                    <td>{{ item.index }}</td>
                                    <td>{{ item.tcr_nmbre }}</td>
                                    <td>{{ item.dcm_ano }}</td>
                                    <td>{{ item.dcm_cdgo }}</td>
                                    <td>{{ item.dcm_fcharad | formatDate }}</td>
                                    <td>{{ item.dcm_entidad }}</td>
                                    <td>{{ item.dcm_asunto }}</td>
                                    <td>{{ item.dep_nmbre }}</td>
                                    <td>{{ item.ser_nmbre }}</td>
                                    <td></td>
                                  </tr>
                              </tbody>
                          </table>
                      </div>
                      <br>
                      <div v-if="showProgress" style="text-align:center">
                        <v-progress-circular
                          :size="50"
                          :width="5"
                          color="blue"
                          indeterminate
                        ></v-progress-circular>
                      </div>
                  </div>
                  <br>
                  <div class="row justify-content-around" align="center">
                    <button type="button" class="btn btn-primary" v-on:click="printReport('report')">Imprimir</button>
                    <button type="button" id="customXLSButton" class="btn btn-primary" v-on:click="exportReport">Exportar</button>
                  </div>
              </div>
          </div>
      </div>
      <!-- End PAge Content -->
  </div>
  <!-- End Container fluid  -->
</template>

<script>
//import { VProgressCircular } from 'vuetify/lib';
import moment from 'moment';
import { formatDate } from './../../plugins/filters';
import print from 'print-js';
import XLSX from 'xlsx';

export default {
  components: {
    //VProgressCircular
  },
  data(){
    return{
      message: '',
      items: [],
      tpocors: [],
      dependencias: [],
      series: [],
      debug: null,
      fecha: null,
      rango: '',
      username: '',
      showProgress: false
    }
  },
  filters: {
    formatDate
  },
  computed:{
    companydata() {
      return this.$store.state.companydata
    }
  },
  created: function(){
    this.fecha = moment(new Date()).format('DD/MMM/YYYY hh:mm A');
    this.fetchTpocor();
    this.fetchDependencia();
    this.fetchSerie();
    this.debug = this.$route.params;
    this.username = this.$route.params.username;
    this.rango = "Desde: " + moment(this.$route.params.query.dcm_fcharadini).format('DD/MMM/YYYY') + " - Hasta: " + moment(this.$route.params.query.dcm_fcharadfin).format('DD/MMM/YYYY');

    this.searchItems(this.$route.params.query);
  },
  mounted: function() {
    /*
    $('#myTable').DataTable({
    dom: 'Bfrtip',
    buttons: [
        'copy',
        'pdf',
        'excel'
    ]
    });
    */
  },
  methods: {
    fetchTpocor()
    {
      let uri = '/tpocors/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.tpocors = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.tpocors.length; j++){
            if (this.items[i].tcr_id == this.tpocors[j]._id){
              this.items[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
              j = this.tpocors.length;
            }
          }
        }

      });
    },
    fetchDependencia()
    {
      let uri = '/deps/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.dependencias = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.dependencias.length; j++){
            if (this.items[i].dep_id == this.dependencias[j]._id){
              this.items[i].dep_nmbre = this.dependencias[j].dep_nmbre;
              j = this.dependencias.length;
            }
          }
        }
        /*
        let m = response.data.index;
        this.files[m]['uploaded'] = '0';
        this.$set(this.files, m, this.files[m]);
        */
      });
    },
    fetchSerie()
    {
      let uri = '/series/' + String(this.$store.state.company);
      this.axios.get(uri).then((response) => {
        this.series = response.data;

        for (var i = 0; i < this.items.length; i++){
          for (var j = 0; j < this.series.length; j++){
            if (this.items[i].ser_id == this.series[j]._id){
              this.items[i].ser_nmbre = this.series[j].ser_nmbre;
              j = this.series.length;
            }
          }
        }

      });
    },
    searchItems(p){
      if (p != null){
        this.debug = p;
        this.showProgress = true;

        p.cmp_id = this.$store.state.company;
        this.message = 'Buscando documentos';
        let uri = '/docs/report/docsradusuario';
        //this.axios.defaults.headers.common['Authorization'] = sessionStorage.getItem('jwtToken');
        this.axios.post(uri, p)
        .then((response) => {
          var list = response.data;
          this.items = [];

          for (var i = 0; i < list.length; i++){
            list[i].index = i + 1;
            for (var j = 0; j < this.tpocors.length; j++){
              if (list[i].tcr_id == this.tpocors[j]._id){
                list[i].tcr_nmbre = this.tpocors[j].tcr_nmbre;
                j = this.tpocors.length;
              }
            }

            for (var j = 0; j < this.dependencias.length; j++){
              if (list[i].dep_id == this.dependencias[j]._id){
                list[i].dep_nmbre = this.dependencias[j].dep_nmbre;
                j = this.dependencias.length;
              }
            }

            for (var j = 0; j < this.series.length; j++){
              if (list[i].ser_id == this.series[j]._id){
                list[i].ser_nmbre = this.series[j].ser_nmbre;
                if (list[i].dcm_tres == undefined){
                  list[i].dcm_tres = this.series[j].ser_tres;
                }
                j = this.series.length;
              }
            }
            
            this.items.push(list[i]);

          }
          this.showProgress = false;

        })
        .catch(err => {
          console.log(err);
          this.message = '¡Error al buscar los documentos' + err;
        });
      }
    },
    printReport(object){
      var report = window.document.getElementById('report');
      var p = window.open('', 'Imprimir');
      p.document.write(
                "<!DOCTYPE html>"+
                "<html>"+
                "<head>"+
                "<link rel='stylesheet' href='../../bootstrap/css/bootstrap.min.css'></link>"+
                "<link rel='stylesheet' href='../../css/report.css'></link>"+
                "</head>"+
                "<body>"+
                    report.innerHTML+
                "</body>"+
                "</html>");
      p.document.close();

      setTimeout(function(){ //giving it 200 milliseconds time to load
              p.focus();
              p.print();
              p.close();
      }, 2000);
    },
    exportReport_inicial(){
      var tableId = 'reporteDocsRadUsuario';
      var ExportButtons = document.getElementById(tableId);

      var instance = new TableExport(ExportButtons, {
          formats: ['xls', 'csv'],
          exportButtons: false,
          trimWhitespace: true
      });

      var XLS = instance.CONSTANTS.FORMAT.XLS;
      //var CSV = instance.CONSTANTS.FORMAT.CSV;

      //                                          // "id"  // format
      var exportDataXLS = instance.getExportData()[tableId][XLS];
      //var exportDataCSV = instance.getExportData()[tableId][CSV];

      // get filesize
      var bytesXLS = instance.getFileSize(exportDataXLS.data, exportDataXLS.fileExtension);
      //var bytesCSV = instance.getFileSize(exportDataCSV.data, exportDataCSV.fileExtension);

      console.log('filesize (XLS):', bytesXLS + 'B');
      //console.log('filesize (CSV):', bytesCSV + 'B');

      var XLSbutton = document.getElementById('customXLSButton');
      //XLSbutton.addEventListener('click', function (e) {
          //                   // data             // mime                 // name                 // extension
          instance.export2file(exportDataXLS.data, exportDataXLS.mimeType, exportDataXLS.filename, exportDataXLS.fileExtension);
      //});

      //var CSVbutton = document.getElementById('customCSVButton');
      //CSVbutton.addEventListener('click', function (e) {
          //                   // data             // mime                 // name                 // extension
          //instance.export2file(exportDataCSV.data, exportDataCSV.mimeType, exportDataCSV.filename, exportDataCSV.fileExtension);
      //});


    },
    exportReport(){
      var tableId = 'reporteDocsRadicados';

      var report = [];
      for (var i = 0; i < this.items.length; i++){
        let item = {};
        item.index = this.items[i].index;
        item.tcr_nmbre = this.items[i].tcr_nmbre;
        item.tcr_nmbre = this.items[i].tcr_nmbre;
        item.dcm_ano = this.items[i].dcm_ano;
        item.dcm_cdgo = this.items[i].dcm_cdgo;
        item.dcm_fcharad = moment(this.items[i].dcm_fcharad).format('DD/MMM/YYYY hh:mm A');
        item.dcm_entidad = this.items[i].dcm_entidad;
        item.dcm_asunto = this.items[i].dcm_asunto;
        item.dep_nmbre = this.items[i].dep_nmbre;
        item.ser_nmbre = this.items[i].ser_nmbre;

        report.push(item);
      }

      /* generate a new workbook with the first rows */
      var ws = XLSX.utils.aoa_to_sheet([
        [this.companydata.cmp_nmbre],
        [this.fecha],
        [],
      	["Reporte de Documentos creados por usuario"],
        ["Usuario: " + this.username],
        [this.rango],
        [],
        ["#", "Clase documento", "Año", "Consecutivo", "Fecha Radicación", "Entidad", "Asunto", "Dependencia", "Serie"]
      ]);

      /* add row objects to sheet starting from cell A5 */
      var itemsWS = XLSX.utils.sheet_add_json(ws, report, { header: [], skipHeader: true, origin: "A9" });

      // A workbook is the name given to an Excel file
      var wb = XLSX.utils.book_new(); // make Workbook of Excel

      // add Worksheet to Workbook
      // Workbook contains one or more worksheets
      XLSX.utils.book_append_sheet(wb, itemsWS, tableId); // sheetAName is name of Worksheet

      // export Excel file
      XLSX.writeFile(wb, tableId + '.xlsx'); // name of the file is 'book.xlsx'

    }

  } // END METHODS
}
</script>

<style>
  @import '../../../public/css/report.css';
</style>
